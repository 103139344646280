import { Swiper, Navigation, Pagination, Autoplay } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Autoplay]);

export default () => {
    var swiper = new Swiper('.gallery-modal-slider', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    });

    let bannerSlide = new Swiper(".banner-container", {
      slidesPerView: 1,
      autoplay: {
        delay: 6000,
      },
      watchOverflow:true,
      pagination: {
        el: ".banner-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".banner-next",
        prevEl: ".banner-prev",
      },
    });

    let partnersSlide = new Swiper(".partners-container", {
      slidesPerView: 1,
      autoplay: {
        delay: 3000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      speed: 2000,
      loop: true,
      watchOverflow:true,
      pagination: {
        el: ".partners-pagination",
        clickable: true,
      },
    });

    let cardsSlide = new Swiper(".cards-container", {
      slidesPerView: 4,
      watchOverflow:true,
      pagination: {
        el: ".cards-pagination",
        clickable: true,
      },
      breakpoints: {
        600: {
          slidesPerView: 1
        },
        960: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 4
        }
      }
    });

    let numbersSlide = new Swiper(".numbers-container", {
      slidesPerView: 3,
      autoplay: {
        delay: 3000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      speed: 2000,
      loop: true,
      watchOverflow:true,
      pagination: {
        el: ".numbers-pagination",
        clickable: true,
      },
      breakpoints: {
        600: {
          slidesPerView: 1
        },
        960: {
          slidesPerView: 3
        },
      }
    });
    let certificationsSlide = new Swiper(".certifications-container", {
      slidesPerView: 5,
      watchOverflow:true,
      pagination: {
        el: ".certifications-pagination",
        clickable: true,
      },
      breakpoints: {
        600: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
      }
    });

    let historySlide = new Swiper(".our-history-container", {
      slidesPerView: 2,
      loop: true,
      watchOverflow: true,
      navigation: {
        prevEl: ".history-prev",
        nextEl: ".history-next",
      },
      breakpoints: {
        1160: {
          slidesPerView: 1
        },
      }
    });
};
